<script setup lang="ts">
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import { formatSailingHits, formatPrice } from '~/utils/formatter'

const props = defineProps({
  itinerary: {
    type: Object,
    required: true,
  },
  departureMonths: {
    type: Object,
    default: null
  },
  isOnlyMobileDesign: {
    type: Boolean
  },
  isButtonDiscover: {
    type: Boolean
  },
  isOffer: {
    type: Boolean
  }
})

const emit = defineEmits(['display-quote-event'])
const sliderDatePrice = ref(null)
const carouselSlider = ref(null)
const carouselImages = ref(extractCarouselImages(props.itinerary))
const slideNumber = ref((carouselImages.value.length === 1 ? 1 : 1.3))

function formatDate(dateStr: string) {
  const date = new Date(dateStr)

  return date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
}

watch(
  () => props.itinerary,
  () => {
    carouselImages.value = extractCarouselImages(props.itinerary)
    slideNumber.value = (carouselImages.value.length === 1 ? 1 : 1.3)
  },
)

onMounted(async () => {
  if ('undefined' !== typeof props.itinerary.sailings) {
    slideToDate(props.itinerary.sailings.hits)
  }
})

onUpdated(async () => {
  if ('undefined' !== typeof props.itinerary.sailings) {
    slideToDate(props.itinerary.sailings.hits)
  }
})

const slideToDate = (itinerariesHits) => {
  if (sliderDatePrice.value) {
    if (props.departureMonths.length > 0) {
      // Get dates between current dates durations
      const filteredDatesInDepartureMonths = itinerariesHits
        .map((item, index) => ({ ...item, index }))
        .filter((item) => {
          return item.departureDate >= props.departureMonths[0] && item.departureDate <= props.departureMonths[1]
        })

      // Get the minimum date from the filtered dates Departure month
      if (filteredDatesInDepartureMonths.length > 0) {
        const minDateObj = filteredDatesInDepartureMonths.reduce((minItem, currentItem) =>
          currentItem.departureDate < minItem.departureDate ? currentItem : minItem,
        )

        sliderDatePrice.value.slideTo(itinerariesHits.findIndex(itinerary => itinerary.departureDate === minDateObj.departureDate) - 1)
      }
    }
    else {
      sliderDatePrice.value.slideTo(itinerariesHits.findIndex(itinerary => itinerary.isBestPrice) - 1)
    }
  }
}

const currentItinerary = ref(null)

watch(
  () => currentItinerary.value,
  () => {
    if (currentItinerary.value) {
      document.body.style.overflow = 'hidden'
    }
    else {
      document.body.style.overflow = 'auto'
    }
  },
)

const toggleItinerary = (itinerary) => {
  currentItinerary.value = itinerary
}

if (props.itinerary.content.advantages) {
  props.itinerary.content.advantages.sort((a, b) => {
    if (a.label === "Départ de France") return -1;
    if (b.label === "Départ de France") return 1;
    if (a.label === "Pension Complète") return -1;
    if (b.label === "Pension Complète") return 1;
    return 0;
  });
}
</script>

<template>
  <div
    v-if="itinerary.bestPrice"
    class="cruiseline-cruise"
    :class="{'cruise--main-information-mobile': $device.isMobile || ($device.isDesktopOrTablet && isOnlyMobileDesign), 'cruiseline-cruise-discover': isButtonDiscover}"
  >
    <div class="cruise--cruiseline">
      <NuxtImg
        loading="lazy"
        width="128"
        format="webp"
        :src="itinerary.ship.cruiseline.image.url"
        :alt="itinerary.image.alt ?? itinerary.ship.cruiseline.name"
      />
    </div>
    <div class="cruise--gallery">
      <UiElementsBadge
        v-if="'hurtigruten' === itinerary.ship.cruiseline.slug || 'ponant' === itinerary.ship.cruiseline.slug"
        color="red"
        :is-rectangle-badge="true"
      >
        Offre Exclusive
      </UiElementsBadge>
      <carousel
        v-if="$device.isDesktopOrTablet  && !isOnlyMobileDesign && carouselImages.length > 2"
        ref="carouselSlider"
        class="cruise--carousel-list"
        :class="{
          only: carouselImages.length == 1,
        }"
        :items-to-show="slideNumber"
      >
        <!--        <slide -->
        <!--          :key="'maps'" -->
        <!--        > -->
        <!--          <CruiseUtilsGoogleMaps -->
        <!--            :unique-id="'map-' + itinerary.id" -->
        <!--            :itineraries="stopovers" -->
        <!--            :css="'v2-'" -->
        <!--          /> -->
        <!--        </slide> -->

        <template
          v-for="(carouselImage, index) in carouselImages"
          :key="carouselImage.file"
        >
          <slide
            v-if="index > 0 && carouselImage.url"
          >
            <NuxtLinkLocale
              :to="{ name: 'cruises-slug', params: { slug: itinerary.slug } }"
            >
              <NuxtImg
                format="webp"
                loading="lazy"
                height="400"
                fit="cover"
                :src="carouselImage.url"
                :alt="carouselImage.alt"
              />
            </NuxtLinkLocale>
          </slide>
        </template>

        <!-- @TODO replace condition 3 > 3 by number of favorite products -->
        <template #addons>
          <navigation>
            <template #prev>
              <UiIconsArrowWithLine direction="left" />
            </template>
            <template #next>
              <UiIconsArrowWithLine direction="right" />
            </template>
          </navigation>
        </template>
      </carousel>
      <NuxtLinkLocale
        v-else-if="$device.isDesktopOrTablet  && !isOnlyMobileDesign && carouselImages.length === 2"
        class="cruise--gallery--image"
        :to="{ name: 'cruises-slug', params: { slug: itinerary.slug } }"
      >
        <NuxtImg
          format="webp"
          loading="lazy"
          height="400"
          fit="cover"
          :src="carouselImages[1].url"
          :alt="carouselImages[1].alt"
        />
      </NuxtLinkLocale>
      <NuxtLinkLocale
        v-else
        class="cruise--gallery--image"
        :to="{ name: 'cruises-slug', params: { slug: itinerary.slug } }"
      >
        <NuxtImg
          width="350"
          height="200"
          fit="cover"
          format="webp"
          :src="isOffer && itinerary.offerImage && itinerary.offerImage.url ? itinerary.offerImage.url : itinerary.image.url"
          :alt="isOffer && itinerary.offerImage && itinerary.offerImage.alt ? itinerary.offerImage.alt : itinerary.image.alt ?? itinerary.content.name"
        />
        <span>{{ itinerary.content.name }}</span>
      </NuxtLinkLocale>
    </div>
    <div
      v-if="$device.isDesktopOrTablet && !isOnlyMobileDesign"
      class="cruise--main-information"
    >
      <div class="cruise-info">
        <NuxtLinkLocale
          :to="{name: 'cruises-slug', params: { slug: itinerary.slug }}"
          class="cruise-name"
        >
          {{ itinerary.content.name }}
        </NuxtLinkLocale>
        <UiElementsPrice
          v-if="$device.isMobile"
          :label="$t('from')"
          :price="itinerary.bestPrice"
          :return-line="false"
        />
        <NuxtLinkLocale
          v-if="itinerary.stopovers"
          class="cruise-link"
          :to="'#'"
          role="button"
          @click="toggleItinerary(itinerary)"
        >
          <UiIconsMap />
          <span>{{ $t('cruiselines.cruise.link') }}</span>
        </NuxtLinkLocale>
      </div>
      <div class="cruise-price-and-options">
        <UiElementsPrice
          v-if="$device.isDesktopOrTablet"
          :label="$t('from')"
          :price="itinerary.bestPrice"
          :return-line="false"
        />
        <ul
          v-if="itinerary.content.advantages"
          class="options-list"
        >
          <li
            v-for="(advantage, index) in itinerary.content.advantages.slice(0,2)"
            :key="index"
          >
            <span
              v-if="advantage.icon"
              class="icon"
            >
              <UiElementsIconLoader
                :icon="advantage.icon"
              />
            </span>
            <span>
              {{ advantage.label }}
            </span>
          </li>
        </ul>
        <div class="container-cta">
          <UiElementsButton
            :variant="'light'"
            :size="'large'"
            :color-style="'outline'"
            :title="$t('button.discover')"
            class="cruise-discover"
            :to="{ name: 'cruises-slug', params: { slug: itinerary.slug } }"
          >
              {{ $t('button.read_more') }}
          </UiElementsButton>
          <UiElementsButton
            :variant="'light'"
            :size="'large'"
            :color-style="'gradiant'"
            :title="$t('button.request_quote')"
            class="cruise-discover ga4-click-quote-open"
            @click.prevent=" emit('display-quote-event', itinerary.slug)"
          >
              {{ $t('button.request_quote') }}
          </UiElementsButton>
        </div>
      </div>
      <div class="cruise-departure-arrival">
        <div
          v-if="itinerary.departurePort && itinerary.departurePort.place"
        >
          <UiIconsAnchor />
          <span>
            {{ $t('cruiselines.cruise.departure') }}
          </span>
          <span>
            <strong>
              {{ itinerary.departurePort.place.name }}
            </strong>
          </span>
        </div>
        <div
          v-if="itinerary.returnPort && itinerary.returnPort.place"
        >
          <span>
            {{ $t('cruiselines.cruise.arrival') }}
          </span>
          <span>
            <strong>
              {{ itinerary.returnPort.place.name }}
            </strong>
          </span>
        </div>
      </div>
      <div class="cruise-duration item-info">
        <div class="duration">
          <span class="item-title">
            {{ $t('cruiselines.cruise.duration') }}
          </span>
          <span class="item-value">
            <UiIconsSun />
            <strong>
              {{ $t('cruises.header.duration_details', { nights_count: itinerary.duration, days_count: itinerary.duration + 1 }) }}
            </strong>
          </span>
        </div>
      </div>
      <div
        class="cruise-ship item-info"
      >
        <span class="item-title">
          {{ $t('cruiselines.cruise.ship') }}
        </span>
        <span class="item-value">
          <strong class="ship-name notranslate">{{ itinerary.ship.name }}</strong>
          <span
            v-if="itinerary.ship.class"
            class="ship-star"
          >
            <template
              v-for="(star, index) in itinerary.ship.class"
              :key="index"
            >
              <UiIconsStarSolid />
            </template>
          </span>
        </span>
      </div>
      <div
        v-if="departureMonths && itinerary.sailings && itinerary.sailings.hits"
        class="departure-date-container"
      >
        <span>
          {{ $t('cruiselines.cruise.departureDates') }}
        </span>
        <div class="departure-dates">
          <carousel
            ref="sliderDatePrice"
            :items-to-show="2"
            :snap-align="'start'"
            :breakpoints="{
              500: {
                itemsToShow: 2.5,
              },
              700: {
                itemsToShow: 3.5,
              },
              992: {
                itemsToShow: 1.5,
              },
              1100: {
                itemsToShow: 2.5,
              },
              1300: {
                itemsToShow: 3.5,
              },
            }"
          >
            <template
              v-for="(departureDate, index) in formatSailingHits(itinerary.sailings.hits)"
              :key="index"
            >
              <slide>
                <NuxtLinkLocale
                  class="departure-date-item"
                  :class="{ 'has-reduction': departureDate.isBestPrice }"
                  :to="{ name: 'cruises-slug', params: { slug: itinerary.slug }, hash: '#date-' + departureDate.departureDate }"
                >
                  <span class="date">{{ formatDate(departureDate.departureDate) }}</span>
                  <span
                    v-if="departureDate.hasReducedPrice"
                    class="price-container"
                  >
                    <span class="old-price">{{ departureDate.price }} €</span>
                    <span class="price special-price">{{ departureDate.specialPrice }} €</span>
                  </span>
                  <span
                    v-else-if="departureDate.bestPrice > 0"
                    class="price-container"
                  >
                    <span class="price">{{ formatPrice(departureDate.bestPrice) }} €</span>
                  </span>
                  <span
                    v-else
                    class="price-container"
                  >
                    <span class="complete">{{ $t('cruises.header.v2.table.complete') }}</span>
                  </span>
                </NuxtLinkLocale>
              </slide>
            </template>

            <template #addons>
              <navigation>
                <template #prev>
                  <UiIconsArrowWithLine direction="left" />
                </template>
                <template #next>
                  <UiIconsArrowWithLine direction="right" />
                </template>
              </navigation>
            </template>
          </carousel>
        </div>
      </div>
    </div>
    <div
      v-else
      class="cruise--main-information"
    >
      <div
        v-if="itinerary.departurePort"
        class="cruise-departure-arrival"
      >
        <UiIconsAnchor />
        <span>
          {{ $t('cruiselines.cruise.departure') }}
        </span>
        <span v-if="itinerary.departurePort.place">
          <strong>
            {{ itinerary.departurePort.place.name }}
          </strong>
        </span>
      </div>
      <div class="duration">
        <UiIconsSun />
        <span>
          {{ itinerary.duration + 1 }} jours
        </span>
        <span>-</span>
        <span class="ship-name notranslate">{{ itinerary.ship.name }}</span>
        <span
          v-if="itinerary.ship.class"
          class="ship-star"
        >
          <UiIconsStarSolid
            v-for="(star, index) in itinerary.ship.class"
            :key="index"
          />
        </span>
      </div>
      <div
        v-if="departureMonths && itinerary.sailings && itinerary.sailings.hits"
        class="departure-date-container"
      >
        <div class="departure-dates">
          <carousel
            ref="sliderDatePrice"
            :items-to-show="2.5"
            :snap-align="'start'"
            :breakpoints="{
              700: {
                itemsToShow: 3.5,
              },
              992: {
                itemsToShow: 1.5,
              },
              1100: {
                itemsToShow: 2.5,
              },
              1300: {
                itemsToShow: 3.5,
              },
            }"
          >
            <slide
              v-for="(departureDate, index) in formatSailingHits(itinerary.sailings.hits)"
              :key="index"
            >
              <NuxtLinkLocale
                class="departure-date-item"
                :class="{ 'has-reduction': departureDate.isBestPrice }"
                :to="{ name: 'cruises-slug', params: { slug: itinerary.slug }, hash: '#date-' + departureDate.departureDate }"
              >
                <span class="date">{{ formatDate(departureDate.departureDate) }}</span>
                <span
                  v-if="departureDate.hasReducedPrice"
                  class="price-container"
                >
                  <span class="old-price">{{ departureDate.price }} €</span>
                  <span class="price special-price">{{ departureDate.specialPrice }} €</span>
                </span>
                <span
                  v-else-if="departureDate.bestPrice > 0"
                  class="price-container"
                >
                  <span class="price">{{ formatPrice(departureDate.bestPrice) }} €</span>
                </span>
                <span
                  v-else
                  class="price-container"
                >
                  <span class="complete">{{ $t('cruises.header.v2.table.complete') }}</span>
                </span>
              </NuxtLinkLocale>
            </slide>

            <template #addons>
              <navigation>
                <template #prev>
                  <UiIconsArrowWithLine direction="left" />
                </template>
                <template #next>
                  <UiIconsArrowWithLine direction="right" />
                </template>
              </navigation>
            </template>
          </carousel>
        </div>
      </div>
      <NuxtLinkLocale
        v-if="itinerary.stopovers"
        class="cruise-link"
        :to="'#'"
        role="button"
        @click="toggleItinerary(itinerary)"
      >
        <UiIconsMap />
        <span>{{ $t('cruiselines.cruise.link') }}</span>
      </NuxtLinkLocale>
      <UiElementsPrice
        v-if="isButtonDiscover && itinerary.bestPrice"
        :label="$t('from')"
        :price="itinerary.bestPrice"
        :return-line="false"
      />
      <div
        v-if="isButtonDiscover"
        class="container-cta"
      >
        <UiElementsButton
          :variant="'light'"
          :size="'large'"
          :color-style="'gradiant'"
          :title="$t('button.discover')"
          class="cruise-discover"
          :to="{ name: 'cruises-slug', params: { slug: itinerary.slug } }"
        >
          {{ $t('button.discover') }}
        </UiElementsButton>
      </div>
      <div
        v-else
        class="container-cta"
      >
        <UiElementsButton
          :variant="'light'"
          :size="'large'"
          :color-style="'outline'"
          :title="$t('button.read_more')"
          class="cruise-discover"
          :to="{ name: 'cruises-slug', params: { slug: itinerary.slug } }"
        >
          {{ $t('button.read_more') }}
        </UiElementsButton>
        <UiElementsButton
          :variant="'light'"
          :size="'large'"
          :color-style="'gradiant'"
          :title="$t('button.request_quote')"
          class="cruise-discover ga4-click-quote-open"
          @click.prevent=" emit('display-quote-event', itinerary.slug)"
        >
          {{ $t('button.request_quote') }}
        </UiElementsButton>
      </div>
    </div>

    <Teleport to="body">
      <UiElementsDefaultModal
        v-if="currentItinerary !== null && currentItinerary.stopovers"
        id="itinerary-modal"
      >
        <template #modal-content>
          <span class="modal-title like-h4">
            {{ $t('cruises.modal.title') }}
          </span>
          <CruiseModalItineraryContent
            :itineraries="formatItineraries(currentItinerary.stopovers.hits)"
          />
        </template>
        <template #close-modal>
          <div
            class="modal-close"
            @click="currentItinerary = null"
          >
            <UiIconsCross />
          </div>
        </template>
      </UiElementsDefaultModal>
    </Teleport>
  </div>
</template>

<style lang="scss">
.cruiseline-cruise {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid var(--color-navy-150);
  margin: 0 .5em 1.4em .5em;
  box-shadow: 0 4px 16px 0 #000E554D;
  background: var(--color-primary-white);

  @media (min-width: map-get($grid-breakpoints, lg)) {
    margin: 0 auto 2rem;
    flex-direction: row;
    align-items: stretch;
  }

  .cruise--cruiseline {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
    background-color: var(--color-white);
    padding: 0.5rem;
    display: flex;
    align-items: center;
    height: 60px;


    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
    }
  }

  .cruise--gallery {
    position:relative;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      width: 35%;
      flex: 0 0 35%;
    }

    .container-badge {
      position: absolute;
      z-index: 1;
      top: 10px;
      right: 10px;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
      display: block;
    }

    &--image {
      span {
        background: linear-gradient(0deg, rgba(0,0,0, 1) 0%, rgba(0,0,0,0) 100%);
        font-family: var(--font-secondary);
        font-size: 22px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        color: white;
        text-decoration: none;
        text-align: left;
        padding: 20px;
      }
    }
  }

  .cruise--main-information {
    padding: 0;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      width: calc(65% - 280px);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1em 0;
    }

    .cruise-info {
      padding: 1rem 1.5rem;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        padding-top: 0;
        padding-bottom: .5rem;
        display: flex;
        flex-direction: column;
      }

      .cruise-name {
        display: inline-block;
        font-size: 1.3rem;
        text-decoration: none;

        @media (min-width: map-get($grid-breakpoints, md)) {
          margin-bottom: .75rem;
          font-size: 1.5rem;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      .cruise-link {
        display: inline-flex;
        align-items: center;
        gap: $space-xs;
        text-decoration: underline;
        cursor: pointer;
        margin-bottom: 1rem;

        @media (min-width: map-get($grid-breakpoints, lg)) {
          font-size: 1.15rem;
          text-underline-offset: 0.4rem;
        }
      }
    }

    .cruise-price-and-options {
      background-color: var(--color-navy-10);
      padding: $space-xs;
      column-gap: $space-xs;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      @media (min-width: map-get($grid-breakpoints, md)) {
        padding: $space-sm $space-md $space-md;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: unset;
      }

      @media (min-width: map-get($grid-breakpoints, lg)) {
        width: 290px;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
      }

      .price--container {
        .price {
          .price-value {
            @media (max-width: map-get($grid-breakpoints, md)) {
              font-size: 2em;
            }
          }
        }
      }

      .price-container {
        > span {
          font-size: 1rem;

          @media (min-width: map-get($grid-breakpoints, md)) {
            display: block;
          }

          .price {
            font-size: 1.5rem;
            font-family: var(--font-secondary);
            display: inline-block;
            margin: 0 $space-xs;
          }
        }

        .reduction {
          display: inline-flex;
          flex-direction: column;

          .old-price {
            text-decoration: line-through;
          }
        }

        .reduction-info {
          margin-top: $space-xs;
          border: 1px solid var(--color-red-150);
          background-color: var(--color-red-10);
          color: var(--color-red);
          padding: 4px $space-xs;

          @media (min-width: map-get($grid-breakpoints, md)) {
            display: inline-block;
            margin-bottom: $space-xs;
          }
        }
      }

      .options-list {
        order: -1;
        list-style: none;
        padding: 0;
        display: flex;
        gap: $space-xs;
        flex-wrap: wrap;

        @media (max-width: map-get($grid-breakpoints, md)) {
          justify-content: space-between;

          li {
            width: 48%;
          }
        }

        @media (min-width: map-get($grid-breakpoints, md)) {
          flex-direction: column;
          order: initial;
          margin-top: auto;
        }

        li {
          display: inline-flex;
          gap: 4px;
          align-items: center;

          .icon {
            font-size: 1.5rem;
            display: inline-flex;
            justify-content: center;
          }
        }
      }

      .cruise-discover {
        align-items: center;
        margin-left: auto;
        flex: 1;

        @media (min-width: map-get($grid-breakpoints, md)) {
          margin: unset;
          font-size: 1.25rem;
          flex: initial;

          svg {
            display: none;
          }
        }

        a {
          color: var(--color-white);
        }
      }
    }

    .cruise-departure-arrival {
      margin-bottom: .5rem;
      padding-top: 1rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: $space-md;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        padding-top: 0;
        font-size: 1.15rem;
        gap: $space-xs;
      }

      > div {
        display: flex;
        gap: 5px;
        align-items: center;
      }

      > span {
        display: inline-block;
      }
    }

    .item-info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      margin-bottom: .5rem;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        justify-content: unset;
        gap: $space-sm;
      }

      .item-title {
        text-transform: uppercase;
      }

      .item-value {
        font-size: 1.15rem;
      }

      &.cruise-duration {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        @media (min-width: map-get($grid-breakpoints, lg)) {
          gap: 2.5rem;
        }

        > div {
          display: flex;
          align-items: center;
          gap: 1.2rem;
          justify-content: flex-start;
          width: 100%;

          @media (max-width: map-get($grid-breakpoints, lg)) {
            justify-content: space-between;
          }

          .item-value {
            display: flex;
            gap: $space-xs;
            align-items: center;
            font-size: 1.15rem;
          }
        }

        .company {
          img {
            max-width: 8rem;

            @media (min-width: map-get($grid-breakpoints, lg)) {
              max-width: 5rem;
            }
          }
        }
      }

      &.cruise-ship {
        align-items: unset;
        padding-bottom: 0;
        margin-bottom: 1rem;

        @media (max-width: map-get($grid-breakpoints, lg)) {
          justify-content: space-between;
        }

        .item-value {
          display: flex;
          flex-direction: column;

          @media (min-width: map-get($grid-breakpoints, lg)) {
            flex-direction: row;
            align-items: center;
            gap: $space-xs;
          }
        }
      }
    }
  }

  .ship-star {
    display: flex;
    justify-content: end;
    font-size: .9em;
    gap: .2em;
    color: #E7AF2A;
  }

  .departure-date-container {
    margin-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      margin-bottom: 0;
    }

    > span {
      display: block;
      text-transform: uppercase;
      padding-bottom: $space-xs;
      font-size: 1rem;
    }

    .departure-dates {
      display: flex;
      flex-direction: column;

      .departure-date-item {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        margin: 0 4px;
        width: 100%;
        border: 2px solid var(--color-navy-30);
        padding: $space-xs $space-md;
        text-decoration: none;

        &:not(:first-of-type) {
          margin-left: $space-xs;
        }

        &.has-reduction {
          border-color: var(--color-green-150);
          background-color: var(--color-green-10);
          color: var(--color-green);

          .old-price {
            font-size: 0.8rem;
            color: var(--color-grey-450);
            text-decoration: line-through;
            display: inline-block;
            margin-right: 4px;
          }
        }

        .price {
          font-size: 1.3em;
          font-family: var(--font-secondary);
        }

        .complete {
          font-size: 1.3em;
          font-family: var(--font-secondary);
          color: var(--color-red);
        }
      }

      .carousel {
        .carousel__track {
          margin: 0
        }

        &:not(:has(.carousel__prev--disabled)) {
          &:before {
            content: ' ';
            height: 100%;
            width: 15%;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
            opacity: 1;
            z-index: 1;
          }
        }

        &:not(:has(.carousel__next--disabled)) {
          &:after {
            content: ' ';
            height: 100%;
            width: 15%;
            position: absolute;
            top: 0;
            right: 0;
            background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
            opacity: 1;
            z-index: 1;
          }
        }

        .carousel__prev, .carousel__next {
          background: var(--color-navy-10);
          padding: .5em;
          font-size: 1em;
          margin: 0;
          z-index: 5;

          &--disabled {
            display: none;
          }
        }
      }
    }
  }

  .container-cta {
    display: flex;
    gap: 0.5em;

    @media (min-width: map-get($grid-breakpoints, md)) {
      flex-direction: column;
    }

    button, a {
      font-size: 1.1em;
      text-align: center;
    }
  }
}

.cruise--main-information-mobile {
  flex-direction: column;

  .cruise--gallery {
    width: 100%;
  }

  .cruise--main-information{
    padding: 20px;
    font-size: 0.88rem;
    width: 100%;

    .cruise-departure-arrival {
      display: flex;
      padding:0;
      gap: .8em;
      margin-bottom: .8em;
    }
  }

  .duration {
    display: flex;
    gap: .8em;
    align-items: center;
    margin-bottom: .8em;
  }

  .price--container {
    margin-bottom: 0.8em;
  }

  .departure-date-container {
    padding: 0;
    margin: 0 0 .5em;
  }

  .cruise-link {
    cursor: pointer;
    padding: 1em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .9em;
    text-decoration: underline;
    margin-bottom: .5em;

    svg {
      font-size: 1.3em;
    }
  }

  .container-cta {
    a, .button {
      flex: 1;
      font-size: 1.17rem;
      padding: 1em 0;
    }
  }
}

.cruiseline-cruise-discover {
  .cruise-link {

    margin-bottom: 0;
    padding: 1em 1em 0.5em;
  }
}

.cruise--carousel-list {
  height: 100%;
  .carousel__viewport {
    height: 100%;

    a {
      height: 100%;
      width: 100%;
    }

    .carousel__track {
      gap: .5em;
      height: 100%;
      margin: 0;

      img {
        object-fit: cover;
        max-height: 322px;
      }
    }
  }

  .carousel__prev, .carousel__next {
    background: var(--color-navy-10);
    padding: .5em;
    font-size: 1em;

    &--disabled {
      display: none;
    }
  }
}

#itinerary-modal {
  .modal-title {
    text-transform: none;
    font-weight: 500;
    margin-top: 0;
  }

  .modal-overlay--modal {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding-top: 0.5rem;
  }

  .modal-close {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    top: 0.5rem;
    right: 1.5rem;
    cursor: pointer;
    background: var(--color-white);

    &:hover {
      background: var(--color-navy-10);
    }
  }
}
</style>
